import React from "react"
import Layout from "../components/layout"
import styled from "styled-components"
import Hero from "../components/hero"
import SEO from "../components/seo"

const FormContainer = styled.div`
  @media (max-width: 640px) {
    .col-md-8 {
      grid-column: span 12;
    }
    .col-md-2 {
      display: none;
    }
  }
`

const Form = styled.div`
  background: var(--button);
  padding: 4rem;
  @media (max-width: 640px) {
    padding: var(--medium);
  }
  margin-bottom: var(--large);
  form {
    text-align: center;
  }
  form h3 {
    color: var(--black);
    margin-bottom: 1rem;
    text-transform: uppercase;
  }
  label {
    text-align: left;
    text-transform: uppercase;
    color: var(--black);
    display: block;
  }
  input {
    width: 100%;
    font-size: 1rem;
    padding: var(--small);
    margin: var(--small) 0;
    border: 1px solid var(--white);
  }
  input,
  span {
    margin: var(--small) 0;
  }
  input[type="submit"] {
    margin: var(--medium) 0 0;
    background: var(--black);
    color: var(--white);
    font-weight: bold;
    padding: var(--medium);
    border: 1px solid var(--black);
    width: 60%;
    cursor: pointer;
    -webkit-appearance: none;
  }
`

const Offrir = () => (
  <Layout>
    <SEO title="Offrir un Soin" />
    <Hero title="Offrir un Soin" hero="offrir.jpg" />
    <div className="container lead highlow">
      <h2>Offrir un bon pour un massage... c'est tout simple !</h2>
      <p>
        S’il est un cadeau « santé » qui fait plaisir à tous les coups (et à
        tous les coûts), c’est bien le massage. Imaginez un peu, vous accordez à
        la personne à qui vous l’offrez une parenthèse enchantée. Objectif, ne
        penser à rien, si ce n’est qu’à soi! Vous avez qui plus est l’embarras
        du choix : un achat directement en ligne, e-mail ou via SMS pour un
        massage du dos, des jambes, un soin du visage ou du corps, etc...
      </p>
      <p>
        Tous les soins mentionnés peuvent être offerts
        <br />
        Je suis à votre disposition pour vous conseiller.
      </p>
      <p>
        Les bons cadeaux sont valables de six mois à une année à compter de leur
        date d'émission.
      </p>
    </div>
    <FormContainer className="container grid">
      <div className="col-md-2"></div>
      <div className="col-md-8">
        <Form>
          <form
            id="offrirsoin"
            name="offrirsoin"
            method="POST"
            action="https://formsubmit.io/send/2fc1942b-e70b-472b-a277-5e9fb5a3a883"
          >
            <h3>Formulaire</h3>
            <input
              name="_redirect"
              type="hidden"
              id="name"
              value="https://elsamassotherapie.ch/merci"
            />
            <label htmlFor="Personnequioffre">
              les coordonnées de la personne qui offre le bon
            </label>
            <input
              type="text"
              name="Personnequioffre"
              id="Personnequioffre"
              required={true}
            />
            <label htmlFor="Beneficiaire">
              les coordonnées du bénéficiaire du bon
            </label>
            <input
              type="text"
              name="Beneficiaire"
              id="Beneficiaire"
              required={true}
            />
            <label htmlFor="Service">
              la valeur du bon ou le nom du massage choisi
            </label>
            <span className="custom-dropdown">
              <select name="Service" id="Service" required={true}>
                <option value="noservice">Choisir le service...</option>
                <option value="massages">Massages</option>
                <option value="soinsvisage">Soins du Visage</option>
                <option value="soinscorps">Soins du Corps</option>
              </select>
            </span>
            <label htmlFor="Adresse">
              l'adresse postale où je vous enverrai le bon.
            </label>
            <input type="text" name="Adresse" id="Adresse" required={true} />
            <label htmlFor="Paiement">Paiement</label>
            <span className="custom-dropdown">
              <select name="Paiement" id="Paiement" required={true}>
                <option value="nopaiement">Choisir le paiement...</option>
                <option value="cash">Cash</option>
                <option value="twint">Twint</option>
              </select>
            </span>
            <input
              name="_formsubmit_id"
              type="text"
              style={{ display: "none" }}
            />
            <input type="submit" value="ENVOYER" />
          </form>
        </Form>
      </div>
      <div className="col-md-2"></div>
    </FormContainer>
    <div className="container lead side-padding">
      <p>
        Dès réception de votre paiement le bon-cadeau sera envoyé par la Poste.
        Pour votre sécurité, l’espace d’Elsa respecte toutes les mesures de
        protection nécessaires.
      </p>
      <h3 style={{ textAlign: "center", padding: "var(--xl2)" }}>
        Pour toutes occasions, pensez aux chèques cadeaux.
      </h3>
    </div>
  </Layout>
)

export default Offrir
